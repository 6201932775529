var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "import-container" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("v-button", {
            attrs: { text: "返回" },
            on: { click: _vm.previous },
          }),
        ],
        1
      ),
      _c(
        "import-template",
        { attrs: { uploadUrl: _vm.importUrl, downloadUrl: _vm.downloadUrl } },
        [
          _c("div", { attrs: { slot: "remark" }, slot: "remark" }, [
            _vm._v("\n      备注\n      "),
            _c("div", [
              _c("p", [
                _vm._v("1、红色部分为必填项，灰色部分非必填（请按照示例填写）"),
              ]),
              _c("p", [
                _vm._v(
                  "2、需要用户信息、服务大类、参与岗位在后台中已存在，才可录入"
                ),
              ]),
              _c("p", [_vm._v("3、填写完毕后，请删除示例；")]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }