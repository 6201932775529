// 获取列表
const getListURL = `${API_CONFIG.controlBaseURL}volunteer/volunteerList`
// 获取服务大类
const getTypeIdURL = `${API_CONFIG.controlBaseURL}volunteer/categoryList`

// 编辑
const editURL = `${API_CONFIG.baseURL}serverSelfRulesAction!updateVolunteer.action`
// 查询详情
const queryURL = `${API_CONFIG.controlBaseURL}volunteer/volunteerDetail`

// 上传照片接口
const uploadURL = `${API_CONFIG.uploadURL}?module=neighbor`

// 志愿者后台新增接口
const volunteerAddUrl = `${API_CONFIG.controlBaseURL}volunteer/volunteerAdd`

// 获取用户列表
const getOwnerList = `${API_CONFIG.baseURL}serverCodewordAction!getOwners.action`

// 志愿者新增导入模板
const importUrl = `${API_CONFIG.controlBaseURL}volunteer/volunteerImport`

// 获取志愿者岗位列表
const dutyListUrl = `${API_CONFIG.controlBaseURL}volunteer/dutyList`

// 当前用于查询用户所属公司接口
const getProjectTreeUrl =  `${API_CONFIG.controlBaseURL}communityNode/getProjectTree`


export {
  getListURL,
  getTypeIdURL,
  editURL,
  queryURL,
  uploadURL,
  volunteerAddUrl,
  importUrl,
  dutyListUrl,
  getOwnerList,
  getProjectTreeUrl,
}
