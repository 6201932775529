<template>
  <div class="import-container">
    <div class="header">
      <v-button text="返回" @click="previous"></v-button>
    </div>
    <import-template :uploadUrl="importUrl" :downloadUrl="downloadUrl">
      <div slot="remark">
        备注
        <div>
          <p>1、红色部分为必填项，灰色部分非必填（请按照示例填写）</p>
          <p>2、需要用户信息、服务大类、参与岗位在后台中已存在，才可录入</p>
          <p>3、填写完毕后，请删除示例；</p>
        </div>
      </div>
    </import-template>
  </div>
</template>
<script>
import { ImportTemplate } from "components/bussiness/index";
import { importUrl } from "./api";
export default {
  name: "importVolunteerPage",
  components: {
    ImportTemplate,
  },
  data() {
    return {
      downloadUrl: "static/excel/志愿者导入模板.xlsx",
      importUrl: importUrl,
    };
  },
  created() {},
  methods: {
    previous() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.import-container {
  text-align: left;
  background: #fff;
}
.header {
  position: absolute;
  top: 20px;
  right: 20px;
  :not(:last-child) {
    margin-right: 8px;
  }
}
</style>
